.js_blog-like-btn{
  display: flex;
  .like-button {
    padding-right: 0;
    svg{
      height: 10px;
    }
  }
  @include media-breakpoint-up(xxl){
    top: 10px;
    right: 5px;
    .like-button {
      svg{
        height: 14px;
      }
    }
  }
  .dots-button{
    padding-right: 0;
  }
  .helper {
    border: 1px solid $black;
    border-radius: 5px;
    top: 0px;
    display: none;
    font-size: 12px;
    padding-bottom: 3px;
    padding-inline: 10px;
    padding-top: 3px;
    position: absolute;
    right: 35px;
    width: max-content;
    background-color: $white;
  }
  button {
    border: none;
    background-color: transparent; 
    &:hover {
      .helper {
        @include media-breakpoint-up(md){
          display: block;
        }
      }
    }
  }
  .message-wrapper {
    position: absolute;
    bottom: 50px;
    width: max-content;
    right: -15px;
    font-weight: normal;
    .clipboard-action {
      font-size: 14px;
      padding-inline: 10px;
      padding-top: 3px;
      padding-bottom: 3px;
      background-color: $white;
      border-radius: 5px;
      position: relative;
      z-index: 5;
      &.success-message {
        border: 2px solid $success;
        color: $success;
      }
      &.error-message {
        border: 2px solid $danger;
        color: $danger;
      }
    }
  }
}