.blog-index{
  @include media-breakpoint-down(md){
    display: flex;
    flex-wrap: wrap;
  }
  &-filter{
    &--mobile{
      @extend .w-100;
      position: sticky;
      bottom: 0;
      background-color: $light; 
      padding: 21px 61px;
      z-index: 12;

      select{
        padding: 0.375rem;
        border: 2px solid $primary;
        color: $black;
        font-weight: 500;
        height: initial;
      }
    }
    &--desktop{
      margin-bottom: 70px;

      button{
        @include font-styles(500, initial, normal);
        background-color: transparent;
        border: none;
        border-bottom: 3px solid $light;
        padding: 24.5px 0;
        margin: 0 40px;
        transition: all .2s;

        &:hover{
          border-bottom: 3px solid $gray-550;
        }
        @include media-breakpoint-up(xl){
          @include font-styles(500, 20px, normal);
        }
        @include media-breakpoint-up(xxl){
          margin: 0 57px;
        }
      }
    }
  }
}