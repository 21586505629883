/* ==============================================================================
    Here you can override bootstrap variables to customize the framework
See what you can override at node_modules/bootstrap/scss/_variables.scss
============================================================================= */


/* ================================== OPTIONS ======================================== */

$enable-caret:                                true !default;
$enable-rounded:                              true !default;
$enable-shadows:                              false !default;
$enable-gradients:                            false !default;
$enable-transitions:                          true !default;
$enable-prefers-reduced-motion-media-query:   true !default;
$enable-hover-media-query:                    false !default; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes:                         true !default;
$enable-pointer-cursor-for-buttons:           true !default;
$enable-print-styles:                         true !default;
$enable-responsive-font-sizes:                true;
$enable-validation-icons:                     true !default;
$enable-deprecation-messages:                 true !default;

/* ================================== COLORS ========================================= */
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-550: #989898;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #0088EB !default;
$indigo: #6610f2 !default;
$purple: #4814A0 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #FF7F41;
$yellow: #F7CA34 !default;
$green: #17D8A3;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$primary: $purple;
$secondary: $green;
$tertiary: $yellow;
$quaternary:$blue;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: #f7f7f7;
$medium-light: #EBEBEB;
$dark: #2C2C2C;


$theme-colors: ();
$theme-colors: map-merge(
        (
            "primary": $primary,
            "secondary": $secondary,
            "tertiary": $tertiary, 
            "fourthly": $quaternary,  /* Color added to classic bootstrap color theme,
                                        can be use for all elements! (btn-tertiary, text-tertiary....)
                                        Don't abuse it, the css output will be heavier! */
            "success": $success,
            "info": $info,
            "warning": $warning,
            "danger": $danger,
            "light": $light,
            "dark": $black
        ),
        $theme-colors
);


/*============================== TYPOGRAPHY =================================== */
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-family-sans-serif: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-family: "DM Serif Display", serif;

/* ================================ GRID ==================================== */

// Want to add a breakpoint? that's how you do it. Don't abuse it, the css output will be heavier!
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1680px
);

// .container max widths custom
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1680px
);