.team-box{
  .coachs{
    margin-top: 1rem;
    .coach{
      position: relative;
      margin-top: 8rem;

      img{
        position: absolute;
        bottom: 0;
        left: 10%;
        max-width: 150px;
      }
      .text{
        background-color: $medium-light;
        padding: 18px 18px 30px;
        .name{
          @include font-styles(600, 18px, 27px);
        }
        .type{
          @include font-styles(500, 14px, 21px);
        }
        
        @include media-breakpoint-up(xxl){
          .name{
            @include font-styles(600, 23px, 34.5px);
          }
          .type{
            @include font-styles(500, 18px, 27px);
          }
        }
      }
    }
  }
}