
.blog-post-page{
  article{
    .js_blog-like-btn{
      padding-bottom: 1.5rem;

      .dots-button,
      .like-button,
      .created_at-reading-time{
        padding: 0;
      }

      .created_at-reading-time{
        margin-bottom: 0;
        margin-left: 20px;
        color: $gray-550;

        @include font-styles(500, 15px, 22.5px);

        @include media-breakpoint-up(lg){
          @include font-styles(500, 18px, 27px);
        }
      }
    }
  }
  .image-thumbnail{
    margin-bottom: 1.5rem;
    object-fit: cover;
    aspect-ratio: 5/3;
    width: 100%;
  }
  aside{
    .latest-articles{
      padding: 20px;

      &--title{
        @include font-styles(600, 18px, 27px);
      }
    }
    @include media-breakpoint-down(md){
      background-color: #f1f1f1;
    }
    @include media-breakpoint-up(lg){
      .latest-articles{
        background-color: #f1f1f1;
        padding: 25px;

        &--title{
          @include font-styles(600, 23px, 34.5px);
        }
      }
    }
  }
}