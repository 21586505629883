footer .newsletter--wrapper{
  .form-group{
    .title{
      @include font-styles(700, 20px, 25px);
      text-transform: uppercase;

      @include media-breakpoint-up(lg){
        @include font-styles(700, 30px, 35px);
        margin-bottom: 31px;
      }
    }
    input.form-control{
      height: initial;
      background-color: transparent;
      border: none;
      border-bottom: 4px solid $white;
      border-radius: 0;
      color: $light;
    }
    button{
      border-radius: 0 50px 50px 0 !important;
      line-height: initial;
      font-size: 13.5px;
      padding: 6px 8px;

      @include media-breakpoint-up(md){
        font-size: 15px;
        padding: 6px 14px;
      }
    }

    p.consent{
      @include font-styles(400, 9px, normal);
      margin-top: 45px;

      @include media-breakpoint-up(lg){
        @include font-styles(400, 10px, normal);
        margin-top: 32px;
        margin-bottom: 42px;
      }
    }
  }
}