.legal-pages{
  h2 {
    @include font-styles(600, 16px, 24px);
    &:not(:first-child){
      margin-top: 70px; 
    }
  }

  @include media-breakpoint-up(xl){
    h2 {
      @include font-styles(600, 25px, 37.5px);
    }
  }
}
