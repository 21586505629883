.dw_back{
  @include font-styles(500, 15px, 22.5px);
  color: $dark;

  &::before{
      content: "\00003C";
      font-size: 30px;
      font-weight: 100;
      margin-right: 5px;
      vertical-align: bottom;
  }
  &:hover{
    color: $primary;
    text-decoration: none;
  }
}