.planning-team {
    margin-bottom: 115px;
    margin-inline: -1%;
    .box-btn {
        position: relative;
        width: 48%;
        max-width: 48%;
        margin-inline: 1%;
        margin-bottom: 2%;
        transition: all .2s;
        p {
            position: absolute;
            top: 30px;
            left: 25px;
            font-weight: 600;
            font-size: 40px;
            text-transform: uppercase;
            color: #FFF;
        }
        img {
            width: 100%;
            height: auto;
        }
        &:hover {
            color: transparent;
            transform: scale(1.05);
        }
    }
}

@media screen and (max-width: 1399px) {
    .planning-team {
        .box-btn {
            p {
                font-size: 28px;
                top: 15px;
                left: 15px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .planning-team {
        .box-btn {
            p {
                font-size: 22px;
                left: 10px;
                top: 8px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .planning-team {
        .box-btn {
            width: 100%;
            max-width: 100%;
            margin-inline: 0;
            p {
                font-size: 30px;
                left: 8px;
                top: 12px;
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .planning-team {
        .box-btn {
            p {
                font-size: 20px;
                left: 5px;
                top: 2px;
            }
        }
    }
}