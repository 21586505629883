* {
  font-family: "Poppins"!important;
  outline: none;
}
img {
  max-width: 100%;
}
p {
  line-height: 28px;
}

.page-title{
  font-size: 30px;
  color: $black;
  font-weight: bold;
  @include media-breakpoint-up(sm){
    font-size: 40px;
  }
  @include media-breakpoint-up(md){
    font-size: 45px;
  }
  @include media-breakpoint-up(xl){
    font-size: 55px;
  }
}
.page-subtitle {
  font-size: 18px;
  font-weight: 600;
  @include media-breakpoint-up(sm){
    font-size: 22px;
  }
  @include media-breakpoint-up(md){
    font-size: 26px;
  }
  @include media-breakpoint-up(xl){
    font-size: 30px;
  }
}
.page-subtitle-medium{
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;

  @include media-breakpoint-up(md){
    font-size: 1.5rem;
    line-height: 2.3rem;
  }

}
.page-text-medium{
  font-size: .8rem;
  line-height: 1.2rem;

  @include media-breakpoint-up(md){
    font-size: 1.25rem;
    line-height: 1.8rem;
    small{
      font-size: .6875rem;
      line-height: 1.25rem;
    }
  }
}

/* Colors */
.primary {
  background-color: $purple;
}
.secondary {
  background-color: $green;
}
.tertiary {
  background-color: $yellow;
}
.fourthly {
  background-color: $blue;
}


/* Containers */
.container-85 {
  @extend .container;
  // width: 95%;
  // max-width: 95%;
  // margin: auto;

  // @include media-breakpoint-up(xl){
  //   width: 85%;
  //   max-width: 85%;
  // }
}

@include media-breakpoint-up(xxl){
  .col-xxl-20{
    flex: 0 0 20%;
    max-width: 20%;
  }
}
.col-60{
  flex: 0 0 60%;
  max-width: 60%;
}
.font-weight-medium{
  font-weight: 600;
}
.cursor-pointer{
  cursor: pointer;
}