#acc_category {
    margin-bottom: 150px;
    .faq-section {
        .category-name {
            color: $purple;
            font-size: 25px;
            font-weight: 600;
        }
        .accordion--item {
            .accordion--item--title {
                display: flex;
                justify-content: space-between;
                margin-bottom: 30px;
                margin-top: 25px;
                .accordion--item--title--text {
                    .question {
                        font-weight: 500;
                        font-size: 18px;
                        text-transform: uppercase;
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
                .accordion--item--title--icon {
                    .dwicon {
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 575px) {
    #acc_category {
        .faq-section {
            .category-name {
                font-size: 18px;
                margin-bottom: 20px;
            }
            .accordion--item {
                .accordion--item--title {
                    margin-bottom: 15px;
                    margin-top: 15px;
                    .accordion--item--title--text {
                        .question {
                            font-size: 14px;
                        }
                    }
                    .accordion--item--title--icon {
                        padding-left: 10px;
                        .dwicon {
                            font-size: 10px;
                        }
                    }
                }
                .accordion--item--content {
                    .accordion--item--content--wrapper {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}