/* BANNER */
.banner {
    position: relative;
    .banner-img {
        width: 100%;
        max-width: 100%;
        min-height: 469px;
        object-fit: cover;
        
        @include media-breakpoint-up(lg){
          min-height: 360px;
          object-position: right;
        }
        @include media-breakpoint-up(xl){
          min-height: 440px;
        }
        @include media-breakpoint-up(xxl){
          min-height: 600px;
        }
    }
    .text {
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 5%;
        padding-left: 5%;
        max-width: 41%;
        height: 100%;
        max-height: 85%;
        .banner-title {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 58px;
            margin-bottom: 85px;
        }
    }
}

/* KEY-POINTS */
.key-points {
    margin: -82.5px auto 82.5px;
    width: 80%;
    max-width: 80%;
    .key-point-wrapper {
        .key-point {
            background-color: $purple;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 15px;
            max-height: 165px;
            padding-right: 5px;
            span {
                max-width: 40%;
                width: 40%;
                height: 165px;
                background-size: cover!important;
                background-position: center!important;
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;
            }
            .kp-name {
                display: flex;
                width: 60%;
                max-width: 60%;
                justify-content: center;
                align-items: center;
                color: #FFF;
                font-size: 22px;
                font-weight: bold;
                margin-bottom: 0;
            }
        }
        &:not(:first-child) {
            .key-point {
                background-color: $yellow;
            }
        }
        &:last-child {
            .key-point {
                background-color: $green;
            }
        }
    }
}

/* BLOCK 1 */
.introduction {
    .block1-title {
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 35px;
        margin-bottom: 60px;

    }
}

/* BLOCK 2 */
.discover-the-box {
    margin: auto;
    max-width: 90%;
    width: 90%;
    margin-top: 140px;
    .title {
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 35px;
        margin-bottom: 50px;
    }
    .block_type2 {
        position: relative;
        margin-bottom: 120px;
        .text-wrapper {
            background-color: $lightGrey;
            padding: 50px;
            &.right {
                padding-right: 22%;
                .btn {
                    background-color: #FFF;
                    color: $purple;
                    &:hover {
                        background-color: $purple;
                        color: #FFF;
                    }
                }
            }
            &.left {
                padding-left: 30%;
            }
            .text {
                text-align: justify;
                .block-title {
                    font-size: 27px;
                    font-weight: bold;
                    margin-bottom: 15px;
                }
            }
            .btn {
                font-size: 20px;
                padding-top: 15px;
                padding-bottom: 15px;
                padding-inline: 20px;
                margin-top: 10px;
            }
        }
        img {
            position: absolute;
            bottom: 0;
            width: auto;
            max-height: 130%;
            &.right {
                right: 50px;
                max-width: 20%;
            }
            &.left {
                left: 50px;
                max-width: 25%;
            }
        }
    }
}

/* CROSSFIT */
.crossfit {
    margin-top: 140px;
    .title {
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
        font-size: 35px;
        margin-bottom: 70px;
    }
    .cards {
        .crossfit-card {
            .img-wrapper {
               img {
                   width: 100%;
                   max-width: 100%;
                   border-radius: 8px;
               }
                .number-banner {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 70px;
                    font-weight: bold;
                    color: #FFF;
                    &.primary {
                        background-color: rgba(72, 20, 160, 0.7);
                        width: 30%;
                        height: 100%;
                        border-top-left-radius: 8px;
                        border-bottom-left-radius: 8px;
                    }
                    &.secondary {
                        background-color: rgba(23, 216, 163, 0.7);
                        width: 100%;
                        height: 30%;
                        bottom: 0;
                        border-bottom-left-radius: 8px;
                        border-bottom-right-radius: 8px;
                    }
                    &.tertiary {
                        background-color: rgba(247, 202, 52, 0.7);
                        width: 100%;
                        height: 30%;
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;
                    }
                    &.fourthly {
                        background-color: rgba(0, 136, 235, 0.7);
                        width: 30%;
                        height: 100%;
                        right: 0;
                        border-top-right-radius: 8px;
                        border-bottom-right-radius: 8px;
                    }
                }
            }
            .text {
                margin-top: 30px;
                p {
                    text-align: justify;
                    font-size: 20px;
                }
                .card-title {
                    text-align: center;
                    font-weight: bold;
                    font-size: 25px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

/* INSTAGRAM */
.instagram {
    margin-top: 170px;
    margin-bottom: 200px;
    .title {
        margin-bottom: 100px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 40px;
        text-align: center;
    }
    .images {
        .img-wrapper {
            margin-bottom: 30px;
            div {
                width: 100%;
                height: 215px;
                background-position: center;
                background-size: cover;
                @include media-breakpoint-up(sm) {
                    height: 200px;
                }
                @include media-breakpoint-up(md) {
                    height: 180px;
                }
                @include media-breakpoint-up(lg) {
                    height: 260px;
                }
                @include media-breakpoint-up(xl) {
                    height: 170px;
                }
                @include media-breakpoint-up(xxl) {
                    height: 260px;
                }
            }
        }
    }
    .text {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 20px;
        .logo-at {
            display: flex;
            flex-direction: column;
            align-items: center;
            img {
                width: 105px;
                height: 105px;
            }
            .at {
                text-align: center;
                align-self: center;
                font-weight: bold;
                font-size: 25px;
                color: $grey;
                text-transform: uppercase;
                margin-top: 22px;
            }
        }
        p {
            text-align: justify;
            align-self: start;
        }
        .btn {
            font-size: 23px;
            margin-top: 30px;
        }
    }
}

//Above 1865px
@media screen and (min-width: 1970px) {
    .banner {
        .text {
            .banner-title {
                font-size: 73px;
            }
        }
    }
}

//Below 1400px
@media screen and (max-width: 1399px) {
    .banner {
        .text {
            .banner-title {
                font-size: 48px;
                margin-bottom: 50px;
            }
        }
    }
    .key-points {
        margin: -55px auto 55px;
        .key-point-wrapper {
            .key-point {
                span {
                    height: 110px;
                }
            }
        }
    }
    .discover-the-box {
        .block_type2 {
            margin-bottom: 80px;
            img {
                max-height: 80%;
                &.left {
                    max-width: 38%;
                }
                &.right {
                    max-width: 20%;
                }
            }
            .text-wrapper {
                &.left {
                    padding-left: 50px;
                    .text {
                        p {
                            padding-left: 45%;
                        }
                    }
                    .btns {
                        padding-left: 45%;
                    }
                }
                &.right {
                    padding-right: 50px;
                    .text {
                        p {
                            padding-right: 20%;
                        }
                    }
                    .btns {
                        padding-right: 20%;
                    }
                }
            }
        }
    }
}

//Below 1200px 
@media screen and (max-width: 1199px) {
    .banner {
        .text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            max-width: 35%;
            padding-bottom: 15%;
            .banner-title {
                font-size: 35px;
                margin-bottom: 30px;
            }
            .btn {
                font-size: 18px;
                padding-bottom: 6px;
                padding-top: 6px;
                padding-inline: 15px;
            }
        }
    }
    .key-points {
        max-width: 90%;
        width: 90%;
        margin: -50px auto 50px;
        .key-point-wrapper {
            .key-point {
                span {
                    height: 100px;
                }
                .kp-name {
                    font-size: 18px;
                }
            }
        }
    }
    .introduction {
        .block1-title {
            font-size: 25px;
        }
    }
    .discover-the-box {
        max-width: 100%;
        width: 100%;
        margin-top: 90px;
    }
    .crossfit {
        .cards {
            .crossfit-card {
                margin-top: 20px;
                .text {
                    margin-top: 15px;
                }
            }
        }
    }
    .instagram {
        margin-bottom: 100px;
        .images {
            width: 100%;
            max-width: 100%;
        }
        .text {
            max-width: 100%;
            padding-left: 0;
            margin-top: 20px;
            .logo-at {
                flex-direction: row;
                margin-bottom: 20px;
                .at {
                    margin-left: 15px;
                }
            }
            .btn {
                margin-top: 30px;
            }
        }
    }
}

//Below 992px
@media screen and (max-width: 991px) {
    .banner {
        height: 100%;
        .text {
            max-width: 100%;
            max-height: 90%;
            padding-left: 10%;
            padding-top: 10%;
            padding-right: 10%;
            .banner-title {
                font-size: 65px;
            }
            .btn {
                font-size: 32px;
                padding-bottom: 15px;
                padding-inline: 60px;
                padding-top: 15px;
            }
        }
    }
    .key-points {
        margin: -92px auto 92px;
        .key-point-wrapper {
            .key-point {
                flex-direction: column;
                max-height: none;
                max-width: 100%;
                width: 200px;
                padding-right: 0;
                span {
                    max-width: 100%;
                    width: 200px;
                    height: 140px;
                    border-top-right-radius: 15px;
                    border-bottom-left-radius: 0px;
                }
                .kp-name {
                    font-size: 18px;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    width: 95%;
                    max-width: 95%;
                }
            }
        }
    }
    .discover-the-box {
        .block_type2 {
            .text-wrapper {
                padding: 20px;
                &.right {
                    padding-right: 20px;
                    .text {
                        p {
                            padding-right: 28%;
                        }
                    }
                    .btns {
                        padding-right: 28%;
                    }
                }
                &.left {
                    padding-left: 20px;
                }
                .btn {
                    font-size: 18px;
                    padding-bottom: 10px;
                    padding-inline: 15px;
                    padding-top: 10px;
                }
            }
           img {
                &.right {
                    right: 20px;
                }
                &.left {
                    left: 20px;
                    max-width: 42%;
                }
            }
        }
    }
}

//Below 768px
@media screen and (max-width: 767px) {
    .banner {
        .text {
            .banner-title {
                font-size: 55px;
            }
            .btns {
                .btn-wrapper {
                    .btn {
                        font-size: 22px;
                    }
                }
            }
        }
    }
    .key-points {
        margin: -62.5px auto 62.5px;
        .key-point-wrapper {
            padding-inline: 5px;
            .key-point {
                width: 220px;
                span {
                    width: 220px;
                    height: 125px;
                }
            }
        }
    }
}

//Below 576px
@media screen and (max-width: 575px) {
    .banner {
        .text {
            max-height: 95%;
            max-width: 400px;
            .banner-title {
                font-size: 33px;
            }
            .btn {
                font-size: 18px;
                padding-bottom: 10px;
                padding-inline: 28px;
                padding-top: 10px;
            }
        }
    }
    .key-points {
        width: 95%;
        max-width: 95%;
        .key-point-wrapper {
            .key-point {
                span {
                    height: 100px;
                    width: 175px;
                }
                .kp-name {
                    font-size: 12px;
                    max-width: 70%;
                    padding-bottom: 15px;
                    padding-top: 15px;
                }
            }
        }
    }
    .discover-the-box {
        margin-top: 80px;
        .title {
            font-size: 25px;
        }
        .block_type2 {
            margin-bottom: 50px;
            .text-wrapper {
                padding-top: 10px;
                padding-bottom: 30px;
                width: 100%;
                max-width: 100%;
                .text {
                    p {
                        font-size: 10px;
                        line-height: 1.4;
                    }
                    .block-title {
                        font-size: 16px;
                        line-height: 1.5;
                    }
                }
                .btns {
                    justify-content: center;
                    .btn {
                        font-size: 10px;
                        padding-bottom: 10px;
                        padding-inline: 10px;
                        padding-top: 10px;
                    }
                }
                &.left {
                    width: 100%;
                    max-width: 100%;
                }
            }
            &.right {
                width: 100%;
                max-width: 100%;
                padding-inline: 15px;
                .text {
                    p {
                        padding-right: 25%;
                    }
                }
            }
            &.left {
                width: 100%;
                max-width: 100%;
                padding-inline: 15px;
            }
            img {
                &.right {
                    right: -15px;
                    max-width:34%;
                }
                &.left {
                    left: -8px;
                    max-width: 48%;
                }
            }
        }
    }
    .crossfit {
        margin-top: 90px;
        .title {
            font-size: 25px;
        }
        .cards {
            .crossfit-card {
                .img-wrapper {
                    .number-banner {
                        font-size: 30px;
                    }
                }
                .text {
                    .card-title {
                        font-size: 20px;
                    }
                    p {
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
            }
        }
    }
    .instagram {
        margin-top: 80px;
        .title {
            margin-bottom: 50px;
            font-size: 25px;
        }
        .images {
            margin-inline: 0;
        }
        .text {
            .logo-at {
                .at {
                    font-size: 20px;
                }
            }
            p {
                font-size: 14px;
                line-height: 1.8;
            }
            .btn {
                font-size: 18px;
            }
        }
    }
}

@media screen and (max-width: 349px) {
    .banner {
        .text {
            .banner-title {
                font-size: 26px;
            }
        }
    }
    .key-points {
        flex-direction: column;
        margin: -45px auto 60px;
        .key-point-wrapper {
            padding-inline: 0;
            max-width: 100%;
            margin-bottom: 15px;
            .key-point {
                width: 100%;
                span {
                    width: 100%;
                    height: 150px;
                }
                .kp-name {
                    font-size: 18px;
                }
            }
        }
    }
}