footer{
  background-color: $darkGrey;
  color: $white;

  .footer-wrapper{
    padding: 30px 45px;

    @include media-breakpoint-up(xl){
      padding: 69px 0 39px;
    }

    .logo-wrapper{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-up(xl){
        align-items: flex-start;
      }
  
      img.logo{
        max-width: calc(100vw/5);
        
        @include media-breakpoint-up(xl){
          max-width:calc(100%*(3/4));
        }
      }
      .social-icons{
        width: 100%;
        justify-content: space-evenly;
        margin-top: 25px;
        padding-bottom: 39px;
        border-bottom: 1px solid rgba($white, .8);

      }
    }
  
    .lists-of-links{
      ul{
        padding: 0;
        margin: 0;
        color: $white;
        list-style: none;
    
        &:first-child{
          margin-top: 40px;
        }
        &:last-child{
          margin-top: 50px;
          margin-bottom: 82px;
        }
    
        li{
          @include font-styles(400, 15px, 22.5px);
          text-transform: uppercase;
    
          &:not(:last-child){
            margin-bottom: 16px;
          }
          &:first-child{
            @include font-styles(700, 18px, 27px);
          }
          a{
            color: $white;
          }
        }
      }

      @include media-breakpoint-up(xl){
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        ul,
        ul:first-child,
        ul:last-child{
          margin: 0;
        }
      }
    }

    @include media-breakpoint-up(xl){
      .newsletter{
        .social-icons{
          a:not(:last-child){
            margin-right: 30px;
          }
        }
      }
    }
  }
}