.crossfiterslang-category{
  >p, ul{
    padding-inline-start: 20px;
  }
  li{
    &::marker{
      font-size: 8px;
    }
    p{
      display: inline;
      line-height: normal;
    }
  }
  @include media-breakpoint-up(xl){
    &.column{
      column-count: 2;
      column-gap: 160px;
    }
    &.line{
      width: calc(100%/2-(160px/2));
    }
    >p, ul{
      padding-inline-start: 25px;
    }
    li{
      &::marker{
        font-size: 12px;
      }
    }
  }
}