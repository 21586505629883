ol.breadcrumb{
  list-style: none;
  display: flex;
  
  li{
    display: flex;
    align-items: center;
    font-weight: 500;

    &:not(:last-child):after{
      content:"\00003E";
      margin-right: 5px;
      margin-left: 5px;
      font-size: 30px;
      font-weight: 100;
    }

    a{
      color: #000;
      font-weight: 500;
      transition: all .2s;

      &:hover{
        color: #4814a0;
      }
    }
  }
}