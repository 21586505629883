// MAPS
$colorsHover: ("1": $blue, "2": $green, "3": $purple, "4": $yellow, "5": $purple);
$colors: ("1": rgba(0, 136, 235, 0.05), "2": rgba(23, 216, 163, 0.05), "3": rgba(72, 20, 160, 0.03), "4": rgba(247, 202, 52, 0.05), "5": rgba(72, 20, 160, 0.05));
$boxes: ("madeleine": $blue, "roncq": $green);

.subscription-offer{
  &-card{
    @include media-breakpoint-down(sm){
      &:nth-child(2n+1){
        padding-right: 7px;
      }
      &:nth-child(2n+2){
        padding-left: 7px;
      }
      &:last-child{
        padding-left: 15px;
        padding-right: 15px;

        @include media-breakpoint-down(sm){
          img{
            margin-right: auto;
            margin-left: 15px;
            max-width: 106px;
          }
        }
        
      }
    }

    @each $number, $color in $colors {
      &:nth-child(#{$number}) {
        .subscription-offer-card--item{
          background-color: $color;
          color: $black;
          &.active {
            background-color: map-get($colorsHover, $number);
            color: $light;
          }
        }
      }
    }

    &--item{
      cursor: pointer;
      position: relative;
      transition: all .2s;
      display: flex;
      flex-direction: column;
      align-items: center;
      min-height: 315px;
      justify-content: space-between;
      padding-top:38px;
    
      img{
        max-height: 202px;
      }
      &:hover{
        max-width: initial;

        .subscription-offer-card--price{
          height: 146px;
          transition: all .4s ease-in-out;

          p{
            margin-bottom: 0;
          }

          @include media-breakpoint-down(sm){
            font-size: .75rem;
            line-height: 1.125rem;
          }
        }
      }
      @include media-breakpoint-up(md){
        min-height: 450px;
        img{
          max-height: initial;
        }
      }
      @include media-breakpoint-up(lg){
        padding-top: 38px;
        max-width: 284px;
        min-height: 430px;
    
        &:hover{
          transform: scale(1.1);
        }
      }
      @include media-breakpoint-up(xl){
        min-height: 426px;
      }

      &-banner{
        position: absolute;
        top: calc(315px-(36px+16px));
        left: 0;
        width: 100%;

        @include media-breakpoint-up(md){
          top: calc(450px-(36px+16px));
        }
        @include media-breakpoint-up(lg){
          top: calc(430px-(36px+16px));
        }
        @include media-breakpoint-up(xl){
          top: calc(426px-(36px+16px));
        }
      }
      ul.mobile-price {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;

        @include media-breakpoint-down(sm){
          p{
            font-size: .75rem;
            line-height: 1.125rem;
          }
        }
      }
    }

    &--price{
      @extend .bg-dark;
      @extend .w-100;
      @extend .rounded-bottom;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 0;
      overflow: hidden;
      transition: all .2s ease-in-out .4s;
    }
  }
}

.subscription-about{
  &--image{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .form-group{
    @each $name, $color in $boxes {
      .box-#{$name} {
        @extend .rounded;
        font-size: 14px;
        font-weight: 500;
        padding: 6px 0;
        border: 2px solid $color;
        color: $color;

        @include media-breakpoint-up(md){
          font-size: 18px;
          padding: 12px 36px;
        }
      }
    }

    @include media-breakpoint-down(sm){
      &.box-item{
        min-width: calc(50%-(1.5rem/2));
        text-align: center;

        label {
          width: 100%;
        }
      }
    }

    input{
      @extend .rounded;
      background-color: $light;
      border: none;
      padding: 21px 14px;
      font-size: 12px;
      
      @include media-breakpoint-up(md){
        font-size: 18px;
        padding: 37px 14px;
      }

      @each $name, $color in $boxes {
        &#box-#{$name}[type="radio"]:checked+label {
          background-color: $color;
          color: $light;
        }
      }

      &[type=radio]{
        @include input-radio;
      }
    }
    
    label{
      font-weight: 500;
    }
  }
  .rgpd-item{
    margin-bottom: 30px;

    label {
      display: flex;
      align-items: center;
      font-size: 10px;
      line-height: 15px;
      
      input[type=checkbox]{
        @include input-checkbox;
      }
    }

    @include media-breakpoint-up(md){
      margin-bottom: 35px;
      label {
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
}

#submit-wrapper-contactFormSubscription{
  text-align: center;
}

.identity-wrapper{
  .identity-item{
    &:first-child{
      padding-right: 7px;
    }
    &:last-child{
      padding-left: 7px;
    }

    @include media-breakpoint-up(xxl){
      &:first-child{
        padding-right: 14px;
      }
      &:last-child{
        padding-left: 14px;
      }
    }
  }
}

#subscription-success-message{
  .dwicon.dwicon-check{
    font-size: 107px;

    @include media-breakpoint-up(lg){
      font-size: 217px;
    }
  }

  @include media-breakpoint-down(sm){
    p{
      font-size: .75rem;
      line-height: 1.125rem;
    }
  }
}

#suscription-step-2,
#suscription-step-3{
  color: $gray-500;
}