@mixin input-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  height: 26px;
  width: 26px;
  background: #f0f0f0;
  border: 1px solid #f0f0f0;
  margin-right: 10px;
  padding: 13px;
  vertical-align: middle;
  
  &:checked{
    background-color: #f0f0f0;
    border: none;
    
    position: relative;

    &:before {
      content: '\2714';
      display: block;
      color: $primary;
      font-size: 29px;
      position: absolute;
      top: 0;
      left: 3px;
    }
  }
}

@mixin input-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  height: 26px;
  width: 26px;
  background: #B5B5B5;
  border: 1px solid #B5B5B5;
  margin-right: 5px;
  padding: 13px;
  border-radius: 50px !important;
  vertical-align: middle;
  
  &:checked{
    background-color: #B5B5B5;
    border: none;
    
    position: relative;

    &:before {
      content: '\2022';
      display: block;
      color: $primary;
      font-size: 39px;
      position: absolute;
      top: -16px;
      left: 5px;
    }
  }
}

@mixin font-styles($fontWeight, $fontSize, $lineHeight){
  font-weight: $fontWeight;
  font-size: $fontSize;
  line-height: $lineHeight;
}