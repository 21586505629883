.planning-box{
  .buttons-image{
    margin-bottom: 40px;
    .btn-wrapper{
      width: 50%;
      a {
        @include font-styles(700, 15px, 20px);
        filter: none;
        height: 100%;
        width: 100%;
        justify-content: center;
        padding-left: 15px;
        padding-right: 15px;
        svg{
          margin-left: 5%;
        }
      }
      &:first-child a{
        border-radius: 8px 0 0 0 !important;
      }
      &:last-child a{
        border-radius: 0 8px 0 0 !important;
        display: flex;
        align-items: center;
      }
    }

    @include media-breakpoint-down(sm){
      .planning-img-wrapper {
        overflow: scroll;
        .planning-image {
          max-width: initial;
        }
        @include media-breakpoint-only(xs){
          width: calc(100% + 15px);
        }
      }
    }

    @include media-breakpoint-up(md){
      display: flex;
      flex-direction: column;
      margin: auto auto 60px;
      .btns{
        width: 91.85%;
        margin-left: auto;

        svg{
          margin-left: 15% !important;
        }
      }
      .planning-img-wrapper{
        img{
          width: 100%;
        }
      }
    }
    @include media-breakpoint-up(xxl){
      max-width: 90%;
      .btn-wrapper{
        a{
          @include font-styles(700, 23px, 34.5px);
        }
      }
    }
  }

  .courses-by-discipline{
    .block-title{
      @include font-styles(600, 18px, 27px);

      @include media-breakpoint-up(xl){
        @include font-styles(600, 30px, 45px);
        text-transform: uppercase;
        margin-bottom: 35px;
      }
    }
    .cards-wrapper{
      &--item{
        margin-bottom: 30px;

        @include media-breakpoint-up(xl){
          margin-bottom: 40px;
        }

        img{
          object-fit: cover; 
          width: 100%; 
          height: 126px; 
          object-position: top center;
          border-radius: 8px 8px 0 0;

          @include media-breakpoint-up(md){
            height: 185px; 
          }
          @include media-breakpoint-up(xxl){
            height: 254px; 
          }
        }
        .course-name{
          @include font-styles(600, 15px, normal);
          z-index: 1;
          background-color: #DDDDDD;
          text-align: center;
          margin-bottom: 0;
          border-radius: 0 0 8px 8px;

          @include media-breakpoint-up(xl){
            @include font-styles(600, 18px, normal);
          }
          @include media-breakpoint-up(xxl){
            @include font-styles(600, 28px, normal);
          }
        }
      }
    }
    .schedule-wrapper {
      z-index: -1;
      position: relative;
      transform: translateY(-8px);
      padding-top: 8px;
      display: none;
      background-color: $light;
      border-radius: 0 0 8px 8px;
      .schedule{
        margin: auto;
        text-align: right;
        width: fit-content;

        p{
          @include font-styles(500, 13px, 19.5px);
          margin-bottom: 0;

          @include media-breakpoint-up(xl){
            @include font-styles(600, 16px, 24px);
          }
          @include media-breakpoint-up(xxl){
            @include font-styles(600, 20px, 30px);
            margin-bottom: revert;
          }
        }
      }
    }
  }
}