.contact{
  position: relative;
  padding-bottom: 115px;

  @include media-breakpoint-up(lg){
    padding-bottom: 0;
    margin-bottom: calc(84px + 80px);
  }
  
  >img{
    position: absolute;
    z-index: -1;
    object-fit: cover;
    height: 100%;
    background-position: center;
    left: 0;
    top: 37px;

    @include media-breakpoint-up(lg){
      top: 80px;
    }
  }

  #scf-contactForm{
    background-color: $white;
    padding: 19px 36px 30px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.25);

    .form-title{
      @include font-styles(700, 1rem, 1.5rem);
      margin-bottom: 20px;
  
      @include media-breakpoint-up(lg){
        @include font-styles(700, 1.875rem, 2.8125rem);
        margin-bottom: 38px;
      }
    }

    input:not(#contactForm-rgpd), textarea, select{
      min-height: 42px;
      border-radius: 0;

      @include media-breakpoint-up(lg){
        min-height: 52px;
      }
    }
    input#contactForm-rgpd{
      margin-right: 8px;
    }
    #submit-wrapper-contactForm{
      text-align: center;
      margin-top: 25px;
      margin-bottom: 0;
    }

    @include media-breakpoint-up(lg){
      padding: 38px 83px 37px;
      max-width: 70%;
      margin-inline: auto;
    }
    @include media-breakpoint-up(xxl){
      max-width: 895px;
    }
  }
}