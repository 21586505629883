// Here you can add some css property to bootstrap classes

@media (min-width: 1680px){
  .container {
    max-width: 1620px;
  }
}

.rounded{
  border-radius: .5rem !important;
}

/**************/
/*** BUTTON ***/
/**************/
.btn {
  @extend .rounded;
  @extend .font-weight-bold;
  color: $white;
  border: none;
  font-size: 18px;
  padding: 7.5px 19px;
  filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.25));

  @include media-breakpoint-up(md){
    padding: 17.5px 49px;
    font-size: 20px;
  }
}

@each $color, $value in $theme-colors {
  @if $color != light {
    .btn-#{$color} {
      color: $white;
      background-color: $value;
      border: 2px solid $value;
      
      &:hover {
          background-color: $white;
          border: 2px solid $value;
          color: $value;
      }
    }
  }
}