.post-wrapper{
  @include media-breakpoint-up(xxl){
    &:nth-child(2n+1){
      padding-right: 57px;
    }
    &:nth-child(2n+0){
      padding-left: 57px;
    }
  }
  &--articleSidebar{
    margin-bottom: 17px !important;

    @include media-breakpoint-up(xxl){
      margin-bottom: 30px !important;
    }
    @include media-breakpoint-up(xxl){
      &>.row{
        min-height: 238px !important;
      }
      .created_at-reading-time{
        margin-bottom: 20px !important;
      }
      .number-of-likes{
        padding: 10px 0 !important;
      }
    }
  }
  &--item{
    background-color: $light;
    border-radius: 5px;
    margin-bottom: 27px;

    @include media-breakpoint-up(xxl){
      margin-bottom: 100px;
    }

    &>.row{
      min-height: 161px;
      @include media-breakpoint-up(sm){
        min-height: 145px;
      }
      @include media-breakpoint-up(md){
        min-height: 190px;
      }
      @include media-breakpoint-up(lg){
        min-height: 235px;
      }
      @include media-breakpoint-up(xl){
        min-height: 210px;
      }
      @include media-breakpoint-up(xxl){
        min-height: 280px;
      }
    }
    &-text{
      padding: 9px 10px 0;
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(xxl){
        padding: 15px 20px 0;
      }
      .created_at-reading-time{
        @include font-styles(500, 10px, 15px);
        margin-bottom: 4px;
      }
      .title{
        @include font-styles(600, 12px, 15px);
        margin-bottom: 4px;
      }
      .content{
        @include font-styles(500, 11px, 15px);
      }
      .like-button-wrapper{
        border-top: 1px solid $black;
        margin-top: auto;
  
        .js_blog-like-btn{
          position: absolute;
          top: 0;
          right: 0;
          z-index: 10;
        }
        .number-of-likes{
          font-size: 12px;
          svg{
            height: 9px;
          }
        }
      }
      @include media-breakpoint-up(md){
        .created_at-reading-time,
        .content{
          @include font-styles(500, 15px, 22.5px);
        }
        .title{
          @include font-styles(600, 18px, 22px);
        }
      }
      @include media-breakpoint-up(xxl){
        .created_at-reading-time{
          margin-bottom: 28px;
        }
        .title{
          margin-bottom: 12px;
        }
        .number-of-likes{
          padding: 15px 0 20px;
        }
      }
    }
  }
}