.block_type1{
  .text-wrapper{
    .text{
      text-align: justify;
      .subtitle{
        @include font-styles(600, 17px, 25.5px);
      }
      p{
        @include font-styles(400, 13px, 19.5px);
        strong{
          @include font-styles(600, 17px, 25.5px);
        }
      }
      .highlight {
        display: inline;
        padding: 0 5px;
        background: linear-gradient(transparent 50%, #17d8a380 0);
      }
    }
    .btn-wrapper{
      margin-top: 20px;
    }
  }

  @include media-breakpoint-up(lg){
    .text-wrapper{
      align-self: flex-end;
      .text{
        .subtitle{
          @include font-styles(600, 25px, 37.5px);
        }
        p{
          @include font-styles(400, initial, 27px);
          strong{
            @include font-styles(700, 25px, 37.5px);
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xxl){
    .img-wrapper{
      padding-right: 28px;
    }
    .text-wrapper{
      padding-left: 28px;
    }
  }
  @include media-breakpoint-down(md){
    .img-items{
      &--item{
        &:first-child{
          padding-right: 7px;
        }
        &:last-child{
          padding-left: 7px;
        }
      }
    }
  }
}

.privatizing-box{
  .block_type1{
    .text-wrapper{
      .text{
        .subtitle{
          @include font-styles(600, 20px, 28px);
        }
      }
    }
  }
}