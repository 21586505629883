.privatizing-box {
    margin-bottom: 135px;
    >p {
        font-weight: 600;
        font-size: 25px;
    }
}
.workspace-shooting {
    margin-bottom: 150px;
    .block_type3 {
        align-items: center;
        .text-side {
            margin-bottom: 70px;
            padding-left: 90px;
            text-align: justify;
            .title {
                font-weight: 600;
                font-size: 20px;
            }
            .btn {
                margin-top: 10px;
                padding-inline: 60px;
                padding-top: 12px;
                padding-bottom: 12px;
                font-size: 18px;
            }
        }
        .pic-side {
            text-align: right;
            height: 419px;
            max-height: 100%;
            border-radius: 8px;
            background-size: cover!important;
            max-width: 100%;
        }
    }
    .block_type3_reversed {
        margin-top: -70px;
        margin-bottom: 70px;
        .pic-side {
            text-align: left;
        }
        .text-side {
            margin-top: 70px;
            margin-bottom: 0;
            padding-left: 30px;
        }
    }
}

@media screen and (max-width: 1199px) {
    .privatizing-box {
        margin-bottom: 100px;
    }
    .workspace-shooting {
        .block_type3 {
            .text-side {
                padding-left: 15px;
                p {
                    font-size: 14px;
                    line-height: 24px;
                }
                .btn {
                    font-size: 16px;
                    margin-top: 5px;
                    padding-bottom: 10px;
                    padding-inline: 58px;
                    padding-top: 10px;
                }
            }
            .pic-side {
                background-position: center!important;
            }
        }
        .flex-row-reverse {
            margin-bottom: 0px;
            .text-side {
                padding-left: 15px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .workspace-shooting {
        margin-bottom: 100px;
        .block_type3 {
            margin-inline: 0;
            .text-side {
                padding-right: 25px;
            }
        }
        .block_type3_reversed {
            .text-side {
                padding-left: 25px;
                padding-right: 15px;
                margin-top: 100px;
            }
        }
    }
} 

@media screen and (max-width: 767px) {
    .workspace-shooting {
        .block_type3_reversed {
            align-items: flex-start;
            .pic-side {
                max-width: 80%;
                min-height: 250px;
            }
            .text-side {
                margin-top: 20px;
                padding-left: 0;
                padding-right: 0;
                .btns {
                    justify-content: center;
                }
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .workspace-shooting {
        margin-bottom: 50px;
        .block_type3 {
            .text-side {
                p {
                    line-height: 20px;
                }
                .title {
                    font-size: 18px;
                }
                .btns {
                    .btn-wrapper {
                        width: 100%;
                        .btn {
                            width: 100%;
                            font-size: 14px;
                            margin-top: 0;
                            padding-bottom: 5px;
                            padding-inline: 20px;
                            padding-top: 5px;
                        }
                    }
                }
            }
        }
        .block_type3_reversed {
            .text-side {
                .btns {
                    .btn-wrapper {
                        width: auto;
                        .btn {
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}