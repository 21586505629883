$hamburger-types              : (collapse-r);
$hamburger-layer-width        : 30px;
$hamburger-layer-height       : 3px;
$hamburger-layer-color        : $purple;
$hamburger-active-layer-color : $tertiary;

@import "~hamburgers/_sass/hamburgers/hamburgers";

// variables for navbar
$mobile-height-navbar: 85px;
$desktop-height-navbar: 100px;
$desktop-scroll-height-navbar: initial;

.navbar{
  transition: all .2s;
  z-index: 10;
  height: $mobile-height-navbar;

  &-brand{
    min-width: 230px;
    margin-right: auto;
    margin-left: auto;
    img{
      max-height: calc(#{$mobile-height-navbar} / 1.5);
    }
  }

  &-collapse{  
    .navbar-nav{
      li{
        @include font-styles(700, 18px, 55px);
        z-index: 10;
        text-transform: uppercase;
        a{
          color: $black;
          &:hover{
            color: $primary;
          }
        }
        &:last-of-type a{
          color: $primary;
          &:hover{
            color: $black;
          }
        }
        &.active a{
          color: $gray-600;
        }
      }
    }

    &--add-ons{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;

      img{
        display: block;
        margin-left: auto;
      }

      .social-medias{
        height: 67px;
        background-color: rgba($color: $black, $alpha: 0.8);
      }
    }
  }
}

@include media-breakpoint-down(lg){
  .navbar{
    &-brand{
      text-align: center;
      min-width: 180px;
    }
    &-collapse{  
      position: absolute;
      top: $mobile-height-navbar;
      left: 0;
      width: 100%;
      background-color: #f6f3fa;
      .navbar-nav{
        padding-top: 40px;
        padding-left: 54px;
        height: calc(100vh-#{$mobile-height-navbar});
      }
    }
  }
}

@include media-breakpoint-up(xl){
  .dw_header-sticky + main {
    padding-top: $desktop-height-navbar;
  }

  .navbar{
    height: $desktop-height-navbar;
    position: initial;
    transition: all .2s;

    &-brand{
      margin-right: initial;
      margin-left: initial;

      img{
        max-height: calc(#{$desktop-height-navbar} / 1.5);
      }
    }

    &-collapse{  
      justify-content: center;
      .navbar-nav{
        justify-content: center;
        li{
          @include font-styles(500, 18px, 30px);
          
          a{
            padding-left: 1.875rem !important;
            padding-right: 1.875rem !important;
          }
          &:last-of-type a,
          &.active a{
            font-weight: 700;
          }
          &.active a{
            color: $black;
          }
        } 
      }
    }
  }
}

@include media-breakpoint-only(xl){
  .navbar{
    .container{
      max-width: 1440px;
    }
  }
}
.sticky {
  @extend .shadow;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1030;
  
  @include media-breakpoint-up(xl){
  height: $desktop-scroll-height-navbar;

  & +  main {
      padding-top: $desktop-height-navbar;
    }
  }
}